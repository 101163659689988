import styled from 'styled-components'

export const CreateContainer = styled.div`
    height: 100%;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    background-color: #1E293B66;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border: none;
    width: 90%;
    margin: 40px auto 0px;

    p{
        color: #ffffff;
        position: relative;
    }

    .quill {
        text-align: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .ql-picker-label {
        padding-top: 2px;
    }

    .Draft {
        background: linear-gradient(270deg, #22D3EE 50%, #0EA5E9 100%);
        border-radius: 30px;
        width: 143px;
        height: 46px;
        margin: 0px 200px 25px 50px;
        position: relative;
    }

    .Publish {
        background: linear-gradient(270deg, #22D3EE 50%, #0EA5E9 100%);
        border-radius: 30px;
        width: 143px;
        height: 46px;
        margin: 0px 25px 25px 200px;
        position: relative;
    }

    .buttons {
        display: flex;
    }

    div {
        width: 100%;
    }
`

export const CreateInput = styled.div`
    height: 100%;
    display: grid;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border: none;
    width: 90%;
    margin: 40px auto 0px;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;

    input {
        width: 100%;
        height: 50px;
        border-radius: 10px;
    }
`