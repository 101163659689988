import { styled } from "styled-components";

export const LatestPostFlex = styled.div`
    display: flex;
    width: 90%;
    margin: 40px auto 0px;

    div:first-child {
        width: 25%;
        flex: 1;
        overflow-y: hidden;
    }

    div:nth-child(2) {
        float: right;
        margin-right: 0px;
        align-items: centre;
        justify-contents: centre;
        overflow-y: hidden;
        p {
            text-align: centre;
        }
    }

    div:nth-child(3) {
        position: relative;
        float: right;
        margin-left: 0px;
        align-items: centre;
        justify-contents: centre;
        overflow-y: hidden;
    }
`

export const PostGrid = styled.div`
    display: grid;
    width: 90%;
    margin: 20px auto 0px;
    row-gap: 50px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    padding: 10px;
`

export const PostCard = styled.div`
    margin: 40px 0px 50px 50px;
    border-radius: 20px;
    background-color: #1E293B66;
    width: 65%;

    @media only screen and (max-width: 768px){
        margin: 0 auto 50px auto;
        width: 53%;
    }

    @media only screen and (max-width: 480px){
        margin: 0px auto 50px auto;
        width: 65%;
    }
`


export const PostFlex = styled.div`
    display: flex;
    

    @media (max-width: 768px) {
        flex-direction: column;
    }

`

export const PostDetail = styled.div`
    width: 100%;
    height: auto !important;
`