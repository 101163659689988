import { styled } from "styled-components";


export const PostGrid = styled.div`
    display: grid;
    width: 90%;
    margin: 20px auto 0px;
    row-gap: 50px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    padding: 10px;

    @media (min-width: 768px) {
        .GridStyle { 
            grid-template-columns: repeat(3, 1fr);
            display: grid;
            width: 90%;
            margin: 20px auto 0px;
            row-gap: 50px;
            justify-content: space-evenly;
            justify-items: center;
            align-content: space-evenly;
            align-items: center;
            padding: 10px; 
        }
    }
`


export const PostDetail = styled.div`
    width: 100%;
    height: auto !important;
`
export const Anchor = styled.a`
    text-decoration: none;
`