import React from "react";
import MM from '../../assets/mmsym.png'
import MMLEFT from '../../assets/mmleft.png'
import MMRIGHT from '../../assets/mmright.png'
import * as S from './styles'

const fullWidth = {
    width: '100%',
    border: 'none',
}

export const AuthorMob = () => {

    return (
        <S.Grid>
            <div>
                <img src={MM} width="300px" alt="millimetre" />
            </div>
            <div>
                <S.CentreGrid>
                    <div style={fullWidth}>
                        <S.TypingAnimation>
                            <i>Hi there, I’m millimetre👋</i>
                        </S.TypingAnimation>
                    </div>
                    <div style={fullWidth}>
                    <p><i>Get My Daily Spacelet at Just ₹30</i></p>
                    </div>
                    <div style={fullWidth}>
                        <button>Buy ₹30</button>    
                    </div>
                </S.CentreGrid>
            </div>
        </S.Grid>
    )
}

export const AuthorDesk = () => {


    return (
        <S.FlexBox>
            <S.LeftImg>
                <img src={MMLEFT} alt="millimetre" />
            </S.LeftImg>
            <S.CentreGrid>
                <div style={fullWidth}>
                    <S.TypingAnimation>
                        <i>Hi there, I’m millimetre👋</i>
                    </S.TypingAnimation>
                </div>
                <div style={fullWidth}>
                    <p><i>Get My Daily Spacelet at Just ₹30</i></p>
                </div>
                <div style={fullWidth}>
                    <button>Buy ₹30</button>    
                </div>
            </S.CentreGrid>
            <div>
                <img src={MMRIGHT} alt="millimetre" />
            </div>
        </S.FlexBox>
    )
}