import React from "react";
import * as S from './styles'
import DOMPurify from 'dompurify'
import TemplatePic from '../../assets/templatepic.png'
import { IPost }  from '../TypesModel/types'
import { Link } from "react-router-dom";

export const PostCard = (props: IPost) => {

    return (
        <S.PostCard>
                <div>
                    <img src={TemplatePic} alt="Post-Thumbnail" />
                </div>
                {/* <div>
                    <p>{props.tag}</p>
                </div> */}
                <div>
                    <Link to={`/blog/${props.id}`}>{props.title}</Link>
                </div>
                <div dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(props.content as string)}}>

                </div>
        </S.PostCard>
    )
}