import React from "react";
import { useState, useEffect } from "react";
import DOMPurify from 'dompurify'
import axios from "axios";
import * as S from './styles'
import { useParams } from "react-router-dom";
import { IPost } from "../TypesModel/types";




export const PostDetail = () => {
    const {slug} = useParams()
    const [post, setPost] = useState<IPost>()
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const [error, setError]: [string, (error: string) => void] = React.useState("");

    useEffect(() => {
        axios
        .get<IPost>(`/api/posts/list/${slug}`).then((response) => {
            setPost(response.data)
            // console.log(response.data)
            setLoading(false)
        })
        .catch(ex => {
            const error =
            ex.response.status === 404
              ? "Resource Not found"
              : "An unexpected error has occurred";
            setError(error);
            setLoading(false);
          });
    }, [])

    if (!post) return null;

    return (
        <S.PostDetail>
                <div>
                    <div>
                        <p>{post.title}</p>
                    </div>
                    <div>
                        <p>{post.tag}</p>
                    </div>
                    <div dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(post.content as string)}}>
                    </div>
                </div>
        </S.PostDetail>
    )
}