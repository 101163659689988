import React from "react";
import { useViewPort } from "../ViewPort";
import { AuthorMob, AuthorDesk } from "./AuthorComponent";

const AboutAuthor = () => {

    const { width } = useViewPort();
    const breakpoint = 768;

    return width < breakpoint ? <AuthorMob /> : <AuthorDesk />;
}

export default AboutAuthor;