import styled from 'styled-components';

export const StyledGrid = styled.div`
    display: grid;
    row-gap: 50px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    background-color: #1E293B66;
    border-radius: 20px;
    border: none;
    width: 90%;
    padding: 20px;
    margin: 20px auto 0px;
  

    p{
        color: #ffffff;
        position: relative;
    }
`

export const NormGrid = styled.div`
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    margin: 20px auto 0px;
    width: 100%;

    p {
        width: 100%;
    }

    div:first-child span {
        color: #22D3EE
    }
`

export const StyledFlexBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #1E293B66;
    border-radius: 20px;
    border: none;
    width: 90%;
    margin: 20px auto 20px;
    
    div {
        border: none;
    }

    p {
        color: #ffffff;
        text-align: left;
        margin: 20px 50px 0px 0px;
    }
    
    .img {
        margin: 25px 25px 25px 50px;
        width: 25%;
    }

    .Project-Text {
        width: 75%;
        height: 100px;
    }

`

export const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const ProjectTitleFlex = styled.div`
    display: flex;
    flex-direction: row;
    
    a {
        color: #22D3EE;
    }
`

export const StyledCategory = styled.div`
    width: 100px;
    height: 30px;
    background: linear-gradient(180deg, rgba(16, 185, 129, 0.2) 0%, rgba(190, 242, 100, 0.2) 100%);
    border-radius: 10px;
    margin-left: 1rem; 

    p {
        margin: 3px 0px 0px 0px;
        text-align: center;
        background: linear-gradient(180deg, #10B981 0%, #BEF264 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

`

export const CentreText = styled.div`
    display: grid;
    row-gap: 10px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    border: none;
    width: 100%;
    margin-down: 5px;


    p{
        color: #ffffff;
        width: 100%;
        text-align: center;
        margin: 20px 20px 20px 20px;   
    }

    .text {
        color: #22D3EE;
    }

    span {
        font-size: 2rem;
    }

    p:first-child {
        margin-top: 50px;
        margin-bottom: 50px;
    }
`
