import styled from 'styled-components';
export const Grid = styled.div`
    display: grid;
    row-gap: 50px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    background-color: #1E293B66;
    border-radius: 20px;
    border: none;
    width: 90%;
    padding: 20px;
    margin: 20px auto 0px;
  

    p{
        color: #ffffff;
        position: relative;
    }
`

export const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #1E293B66;
    border-radius: 20px;
    border: none;
    width: 90%;
    margin: 40px auto 0px;
    
    div {
        width: 33%;
        justify-content: center;
        align-items: center; 
        border: none;
    }

    p {
        color: #ffffff;
        text-align: center;
    }
`

export const LeftImg = styled.div`
    img {
        float: right;
    }
`

export const CentreGrid = styled.div`
    display: grid;
    row-gap: 10px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    border: none;
    width: 100%;
    margin-down: 5px;


    p{
        color: #ffffff;
        width: 100%;
        margin: 5px auto 10px;
        text-align: center;
        
    }

    button {
        background: linear-gradient(270deg, #22D3EE 50%, #0EA5E9 100%);
        border-radius: 30px;
        width: 143px;
        height: 46px;
        margin: 0px 25px 25px 50px;
        position: relative;
    }

`

export const TypingAnimation = styled.p`
    color: #ffffff;
    width: 100%;
    margin: 5px auto 10px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    animation: typing;
    animation-duration: 1.5s;
    animation-timing-function: steps(30, end);
    animation-fill-mode: forwards;

    @keyframes typing {
        from { width: 0 }
        to { width: 100% }
    }
`