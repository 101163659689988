import React, { useEffect, useState } from "react";
import ViewPost from '../../assets/viewpost.png'
import * as S from './styles'
import axios from "axios";
import { PostCard } from "./PostCard";
import {IPost} from "../TypesModel/types";


const defaultPosts: IPost[] = []

export const Posts = () => {

    const [posts, setPosts]: [IPost[],(posts: IPost[]) => void] = useState(defaultPosts)
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const [error, setError]: [string, (error: string) => void] = React.useState("");

    useEffect(() => {
        axios
        .get<IPost[]>('/api/posts/list/').then((response) => {
            setPosts(response.data)
            setLoading(false)
        })
        .catch(ex => {
            const error =
            ex.response.status === 404
              ? "Resource Not found"
              : "An unexpected error has occurred";
            setError(error);
            setLoading(false);
          });
    }, [])


    return (
        <div>
            <S.LatestPostFlex>
                <div>
                    <p>Latest Posts</p>
                </div>
                <div>
                    <p>View all Posts</p>
                </div>
                <div>
                    <img src={ViewPost} alt="View-all-Post" />
                </div>
            </S.LatestPostFlex>
            <div>
                <div>
                    <S.PostGrid>
                        <S.PostFlex>
                            {
                                Array.isArray(posts)
                                ? posts.map((post, index) => {
                                    if(index <= 2) {
                                        return (
                                                <PostCard 
                                                    key={post.id} 
                                                    id={post.id} 
                                                    title={post.title} 
                                                    tag={post.tag}
                                                    slug={post.slug} 
                                                    published_on={post.published_on} 
                                                    content={post.content}
                                            />
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })
                            :null}
                        </S.PostFlex>
                    </S.PostGrid>
                </div> 
            </div>
        </div>
    )
}