import React, { useState } from "react";
import Logo from "../../assets/logo.svg"
import { LoginContainer } from "./LoginStyles";
import axios from "axios";

export const Login = () => {

    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')

    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'

    const login = () => {
        axios
            .post('mmroot/', {
                username: username,
                password: password
            })
            .then((response) => {
                console.log("Successfully Logged In")
            })
    }

    const handleClick = () => {
        console.log(username)
        console.log(password)
    }

    return (
        <LoginContainer>
                <img src={Logo} alt="M2-Space" height="70px" width="160px"/>
                <input type="text" placeholder="username" onChange={(e) => setUserName(e.target.value)}/>
                <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)}/>
                <button className="Login" onClick={login}>Login</button>
        </LoginContainer>
    )
}