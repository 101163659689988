import React from "react";
import IDEA from '../../assets/idea.png'
import * as S from './styles'


export const ProjectSpaceMob = () => {
    return (
        <S.NormGrid>
            <div>
                <p><b>Recent <span>Projects</span></b></p>
            </div>
            <S.StyledGrid>
                <div>
                    <img src={IDEA} alt="Project-Icon" width="200px" height="150px" />
                </div>
                <div>
                    <S.FlexBox>
                        <div>
                            <i><b><a href="/">m<sup>2</sup>space</a></b></i>
                        </div>
                        <S.StyledCategory>
                            <p>Web Design</p>
                        </S.StyledCategory>
                    </S.FlexBox>
                    <S.CentreText>
                        <p>Suspendisse bibendum.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse bibendum.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse bibendum.</p>
                    </S.CentreText>
                </div>
            </S.StyledGrid>
        </S.NormGrid>
    )
}

export const ProjectSpaceDesk = () => {
    return (
        <div>
            <S.CentreText>
                <p><b><span>Recent <span className="text">Projects</span></span></b></p>
            </S.CentreText>
            <S.StyledFlexBox>
                <div className="img">
                    <img src={IDEA} alt="Project-Icon" width="200px" height="150px" />
                </div>
                <div className="Project-Text">
                    <S.ProjectTitleFlex>
                        <div>
                            <i><b><a href="/">m<sup>2</sup>space</a></b></i>
                        </div>
                        <S.StyledCategory>
                            <p>Web Design</p>
                        </S.StyledCategory>
                    </S.ProjectTitleFlex>
                    <div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse bibendum.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse bibendum.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse bibendum.</p>
                    </div>
                </div>
            </S.StyledFlexBox>
        </div>
    )
}