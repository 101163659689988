import { styled } from "styled-components";

export const FooterPropery = styled.div`
    margin-top: 50px;
`

export const FooterLine = styled.div`
    border: 1px solid #CBD5E1;

    @media (max-width: 768px) {
        border: 1px solid #CBD5E1;
    }   
`

export const FooterText = styled.div`
    
    p {
        text-align: left;
        margin: 40px 5px 25px 60px;
        
    }

    span {
        color: #22D3EE;
        font-family: 'MedievalSharp', cursive;
        font-size: 20px;
    }

    @media (max-width: 768px) {
        p {
            text-align: center;
            margin: 40px;
        }
    }   
`