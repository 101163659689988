import React from "react";
import * as S from './styles'

export const Footer = () => {

    const Year = new Date().getFullYear();

    return (
        <S.FooterPropery>
            <S.FooterLine>

            </S.FooterLine>
            <S.FooterText>
                <p>© Copyright {Year} by <span>m<sup>2</sup>Space</span></p>
            </S.FooterText>
        </S.FooterPropery>
    )
}