import React from 'react';

import { Posts } from '../Components/Posts/Posts';
import AboutAuthor from '../Components/Author/AboutAuthor';
import ProjectSpace from '../Components/ProjectSpace/ProjectSpace';

const Home = () => {
    return (
        <div>
            <AboutAuthor />
            <ProjectSpace />
            <Posts />
        </div>
    )
}

export default Home;