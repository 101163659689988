import React from "react";
import { useViewPort } from "../ViewPort";
import { ProjectSpaceMob, ProjectSpaceDesk } from "./ProjectSpaceComponent";

const ProjectSpace = () => {
    const { width } = useViewPort();
    const breakpoint = 768;

    return width < breakpoint ? <ProjectSpaceMob /> : <ProjectSpaceDesk />;
}

export default ProjectSpace;