import React from "react";
import { useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react'
import * as S from "./CreatePostStyles"



export const CreatePost = () => {

    const [ value, setValue] = useState("")
    const editorRef = useRef("");

    const handleEditorChange = (content: any, editor: any) => {
        console.log("Content was updated:", content);
      };

    return (
        <S.CreateContainer>
            <div>
                <S.CreateInput>                
                    <input type="text" placeholder="Title"/>
                    <input type="text" placeholder="Tag"/>
                    <input type="text" placeholder="Slug"/>
                    <input type="datetime-local" placeholder="Date"/>
                </S.CreateInput>
                <Editor
        apiKey="dpcm498bciucnafpf53ee6qp51uaobmqiecei53nqf3xi37u"
        // initialValue="<p>This is the initial content of the editor</p>"
        init={{
          skin: "snow",
          icons: "thin",
          placeholder: "Ask a question or post an update...",

          height: 200,
          menubar: true,
          plugins: [
            "textcolor save link image media preview codesample contextmenu",
            "table code lists fullscreen  insertdatetime  nonbreaking",
            "contextmenu directionality searchreplace wordcount visualblocks",
            "visualchars code fullscreen autolink lists  charmap print  hr",
            "anchor pagebreak"
          ],
          textcolor_rows: "4",

          toolbar1:
            "fullscreen preview bold italic underline | fontselect" +
            "fontsizeselect  | forecolor backcolor | alignleft alignright" +
            "aligncenter alignjustify | indent outdent | bullist numlist table"+
            "link image media | codesample",
        
           toolbar2:
            "visualblocks visualchars" +
            "charmap hr pagebreak nonbreaking anchor |  code",
            
        contextmenu: "formats | link image",
        }}
        onEditorChange={handleEditorChange}
        // toolbar="code"
      />
                            
                <div className="buttons">
                    <button className="Draft">Save as Draft</button>
                    <button className="Publish">Publish</button>
                </div>
            </div>
        </S.CreateContainer>
    )
}

export default CreatePost
