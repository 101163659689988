import styled from 'styled-components'

export const LoginContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    background-color: #1E293B66;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border: none;
    width: 90%;
    margin: 40px auto 0px;
    grid-gap: 0px;
    padding: 20px;

    .Login {
        background: linear-gradient(270deg, #22D3EE 50%, #0EA5E9 100%);
        border-radius: 30px;
        width: 143px;
        height: 46px;
        margin: 20px;
        position: relative;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
    }

    input {
        height: 50px;
        border-radius: 10px;
        margin-top: 25px;

    }


`

