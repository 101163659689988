import React from "react";
import * as S from './styles'
import Logo from '../../assets/logo.svg';


import { 
    BrowserRouter as Router, 
    Routes, 
    Route, 
    NavLink, 
} from "react-router-dom";
import Home from "../../Pages/Home";
import Blog from "../../Pages/Blog";   
import { PostDetail } from "../Posts/PostDetail";
import CreatePost from "../Posts/CreatePost";
import { Login } from "../Authentication/Login";

type Props = {
    open: boolean;
}

function RightNav(props: Props) {

    // const navigate = useNavigate();

    return (
        <Router>
            <S.Ul open={props.open}>
                <S.LogoUl src={Logo} alt={'M2-Space'} />
                <NavLink to="/"
                    style = {{
                    fontWeight: "bold",
                    color: "#0DADEA"
                    }}
                >
                    <li>Home</li>
                </NavLink>
                <NavLink to="/blog"
                    style = {{
                    fontWeight: "bold",
                    color: "#0DADEA"
                    }}
                >
                    <li>Blog</li>
                </NavLink>
                <NavLink to="/Blog"
                    style = {{
                    fontWeight: "bold",
                    color: "#0DADEA"
                    }}
                >
                    <li>Spacelet</li>
                </NavLink>
                <NavLink to="/Blog"
                    style = {{
                    fontWeight: "bold",
                    color: "#0DADEA"
                    }}
                >
                    <li>Timelet</li>
                </NavLink>
                <NavLink to="/Blog"
                    style = {{
                    fontWeight: "bold",
                    color: "#0DADEA"
                    }}
                >
                    <li>WA NewsLetter</li>
                </NavLink>
            </S.Ul>
            
            <Routes>
                <Route path='/' Component={Home} />
                <Route path='/blog' Component={Blog} />
                <Route path='/blog/:slug' Component={PostDetail} />
                <Route path='/create' Component={CreatePost} />
                <Route path='/mmin' Component={Login} />
            </Routes>

        
        </Router>

       
    )
}

export default RightNav