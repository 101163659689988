import React, { useEffect, useState } from "react";
import * as S from '../PageStyles/BlogStyle'
import axios from "axios";
import { PostCard } from "../Components/Posts/PostCard";
import {IPost} from "../Components/TypesModel/types";
import CreatePost from "../Components/Posts/CreatePost";
import { Link } from "react-router-dom";


const defaultPosts: IPost[] = []

export const Blog = () => {

    const [posts, setPosts]: [IPost[],(posts: IPost[]) => void] = useState(defaultPosts)
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const [error, setError]: [string, (error: string) => void] = React.useState("");

    useEffect(() => {
        axios
        .get<IPost[]>('/api/posts/list/').then((response) => {
            setPosts(response.data)
            setLoading(false)
        })
        .catch(ex => {
            const error =
            ex.response.status === 404
              ? "Resource Not found"
              : "An unexpected error has occurred";
            setError(error);
            setLoading(false);
          });
    }, [])


    return (
        <div>
            <div>
                <Link to="/create">Create Post</Link>
                <Link to="/mmin">MMin</Link>
                <div>
                    <S.PostGrid>
                            <div className="GridStyle">
                                {
                                    Array.isArray(posts)
                                    ? posts.map((post, index) => {
                                        return (
                                                <PostCard
                                                    key={post.id} 
                                                    id={post.id} 
                                                    title={post.title} 
                                                    tag={post.tag}
                                                    slug={post.slug}
                                                    published_on={post.published_on} 
                                                    content={post.content}
                                            />
                                        )
                                    })
                                : null}
                            </div>
                    </S.PostGrid>
                </div> 
            </div>
        </div>
    )
}

export default Blog